function visi(MuID) {
  var Win = window.open("/db/ContMan.Visi.Card?pNid=no&pUser="+MuID,"_blank","width=800,height=600,resizable=1,status=1,toolbar=1,location=0,scrollbars=1");
}

function HelpWin(MsgNr) {
  var Win = window.open("/db/FzrTools.Msg.CmsPage?pNid=no&pMsgId="+MsgNr,"_blank","width=800,height=600,resizable=1,status=1,toolbar=1,location=0,scrollbars=1");
}

function JsWin(Url) {
  var Win = window.open(Url,"_blank","width=800,height=600,resizable=1,status=1,toolbar=1,location=0,scrollbars=1");
}

function MM_openBrWindow(theURL,winName,features) {
  window.open(theURL,winName,features);
}

function GetDateHH24MI() {
var jetzt = new Date();
var Tag = jetzt.getDate();
var Monat = jetzt.getMonth()+1;
var Jahr = jetzt.getFullYear();
var Std = jetzt.getHours();
var Min = jetzt.getMinutes();

var TagAusgabe = ((Tag < 10) ? "0" + Tag : Tag);
var MonatAusgabe = ((Monat < 10) ? "0" + Monat : Monat);
var StdAusgabe = ((Std < 10) ? "0" + Std : Std);
var MinAusgabe = ((Min < 10) ? "0" + Min : Min);
return TagAusgabe + "." + MonatAusgabe + "." + Jahr + " " + StdAusgabe + ":" + MinAusgabe;
}

function GetDateHH24MISS() {
var jetzt = new Date();
var Tag = jetzt.getDate();
var Monat = jetzt.getMonth()+1;
var Jahr = jetzt.getFullYear();
var Std = jetzt.getHours();
var Min = jetzt.getMinutes();
var Sec = jetzt.getSeconds();

var TagAusgabe = ((Tag < 10) ? "0" + Tag : Tag);
var MonatAusgabe = ((Monat < 10) ? "0" + Monat : Monat);
var StdAusgabe = ((Std < 10) ? "0" + Std : Std);
var MinAusgabe = ((Min < 10) ? "0" + Min : Min);
var SecAusgabe = ((Sec < 10) ? "0" + Sec : Sec);
return TagAusgabe + "." + MonatAusgabe + "." + Jahr + " " + StdAusgabe + ":" + MinAusgabe + ":" + SecAusgabe;
}

function GetTimeHH24MI() {
var jetzt = new Date();
var Std = jetzt.getHours();
var Min = jetzt.getMinutes();

var StdAusgabe = ((Std < 10) ? "0" + Std : Std);
var MinAusgabe = ((Min < 10) ? "0" + Min : Min);
return StdAusgabe + ":" + MinAusgabe;
}


function FWFW() {
  if (document.forms[1].pOrdnerId[0].value == document.forms[1].pOrdnerId[0].value) {
    window.alert("Test" + document.forms[1].pOrdnerId[0].value)
  }
}

function AddBookmarkIntranet(Target, Title) {
  //alert(Target);
  req = new XMLHttpRequest();
  url = "!ContMan.Intranet.NewBookmark?pTarget="+encodeURIComponent(Target)+"&pTitle="+encodeURIComponent(Title);
  //alert (url);
  req.open("GET", url, false);
  req.send(null);
  //this.innerHtml=req.responseText;
  alert(req.responseText);
  //alert(req.responseText);
}

